import * as Actions from "../actions";

const initialState = () => ({
  error: null,
  fetching: false,
  data: {
    schemaFilters: [],
    selectedFilter: {
      id: 0,
      communityId: "",
      title: "",
      idx: "",
      itemType: "DATA",
    },
  },
});

const reducer = (state = initialState(), {type, payload} = null) => {
  switch (type) {
  case Actions.SCHEMA_FILTER_SET_SELECTED:
    return {
      ...state,
      error: null,
      fetching: false,
      data: {
        ...state.data,
        selectedFilter: payload,
      },
    };
  case Actions.SCHEMA_FILTER_SET:
    return {
      ...state,
      error: null,
      fetching: false,
      data: {
        ...state.data,
        schemaFilters: payload,
      },
    };
  case Actions.SCHEMA_FILTER_FETCHING:
    return {
      ...state,
      fetching: true,
    };
  case Actions.SCHEMA_FILTER_ERROR:
    return {
      ...state,
      fetching: false,
      error: payload,
    };
  case Actions.SCHEMA_FILTER_RESET:
    return initialState();
  default:
    return state;
  }
};

export default reducer;
