import {lazy} from "react";
import ROLE from "app/values/role";

const config = {
  auth: ROLE.admin,
  settings: {layout: "admin"},
  routes: [
    {
      exact: false,
      path: "/manage",
      component: lazy(() => import("./ManageWrapper")),
    },
    // {
    //   exact: true,
    //   path: "/manage/profile",
    //   component: lazy(() => import("./ProfilePage")),
    // },
  ],
};

export default config;
