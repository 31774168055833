import thunk from "redux-thunk";
import * as reduxModule from "redux";
import {persistStore} from "redux-persist";
import rootReducer from "./reducers/index";

const {applyMiddleware, compose, createStore} = reduxModule;
/*
Fix for Firefox redux dev tools extension
https://github.com/zalmoxisus/redux-devtools-instrument/pull/19#issuecomment-400637274
 */
reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = "@@redux/INIT";

const enhancer = compose(applyMiddleware(thunk));

const store = createStore(rootReducer, enhancer);

const persistor = persistStore(store);

export {store, persistor};
