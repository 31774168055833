export const ACCOUNT_SET_DATA = "ACCOUNT_SET_DATA";
export const ACCOUNT_REMOVE_DATA = "ACCOUNT_REMOVE_DATA";
export const ACCOUNT_LOGGED_OUT = "ACCOUNT_LOGGED_OUT";

/**
 * Set User Data
 */
export const setAccountData = data => dispatch => {
  dispatch({
    type: ACCOUNT_SET_DATA,
    payload: data,
  });
};

/**
 * Remove Acount Data
 */
export const removeAccountData = () => ({type: ACCOUNT_REMOVE_DATA});

/**
 * Logout
 */
export const logout = () => (dispatch, getState) => {
  const {account} = getState().auth;

  if (!account.role || account.role.length === 0) {
    return null;
  }

  history.push("/");
  window.setSession(null);

  return dispatch({type: ACCOUNT_LOGGED_OUT});
};
