import React from "react";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {Router} from "react-router-dom";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";

import {createBrowserHistory} from "history";

import "assets/scss/traaax.scss?v=1.0.0";

import "app/i18n/i18n.config";

import Layout from "app/layout/layout";

import AppContext from "./app_context";
import routes from "./configs/routes.config";
import {store, persistor} from "./store";

const hist = createBrowserHistory();

const theme = createMuiTheme({
  typography: {
    fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    body1: {
      "fontWeight": 300,
      "fontSize": 16,
      "lineHeight": 1.8,
      "@media (max-width:600px)": {fontSize: 14},
    },
    body2: {
      fontWeight: 300,
      fontSize: 14,
      lineHeight: 1.5,
    },
    caption: {
      fontWeight: 300,
      fontSize: 12,
    },
    h2: {
      fontWeight: 500,
      fontSize: 60,
    },
    h3: {
      "fontWeight": 400,
      "fontSize": 48,
      "@media (max-width:600px)": {fontSize: 34},
    },
    h4: {
      fontWeight: 400,
      fontSize: 34,
    },
    h5: {
      "fontWeight": 500,
      "fontSize": 24,
      "@media (max-width:600px)": {fontSize: 22},
    },
    h6: {
      fontWeight: 500,
      fontSize: 20,
    },
  },
  palette: {
    primary: {
      light: "#5977b3",
      main: "#123d93",
      dark: "#0d2f80",
      contrastText: "#fdfdfd",
      red: "#d33333",
    },
    secondary: {
      light: "#b968c8",
      main: "#9b27b0",
      dark: "#891da0",
      contrastText: "#f0f0f0",
    },
    text: {
      primary: "#454544",
      secondary: "rgba(38, 38, 38, 0.60)", // #262626
      disabled: "rgba(38, 38, 38, 0.38)", // #262626
    },
    background: {
      default: "#fdfdfd",
      paper: "#fdfdfd",
    },
    highlight: {main: "#cdd9e7"},
    navbar: {
      main: "#f0f0f0",
      contrastText: "#454544",
    },
    footer: {
      main: "#454544",
      contrastText: "#f0f0f0",
    },
    table: {head: "#51a3d3"},
  },
});

const App = () => (
  <AppContext.Provider value={{routes}}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={hist}>
            <Layout />
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </AppContext.Provider>
);

App.displayName = "App";

export default App;
