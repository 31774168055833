import * as Actions from "../actions";

const initialState = {data: {token: null}};

const reducer = (state = initialState, {type, payload} = null) => {
  switch (type) {
  case Actions.ACCOUNT_SET_DATA:
    return {
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    };
  case Actions.ACCOUNT_REMOVE_DATA:
    return {...initialState};
  case Actions.ACCOUNT_LOGGED_OUT:
    return initialState;
  default:
    return state;
  }
};

export default reducer;
