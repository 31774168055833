const locale = {
  // Navbar
  "Home": "Home",
  "Products": "Products",
  "About": "About",
  "Contact": "Contact",

  // Language
  "English": "English",
  "Vietnamese": "Vietnamese",

  // Footer
  "Hotline": "Hotline",
  "Contact Information": "Contact Information",
  "Contact us for consultations on product solutions":
    "Contact us for consultations on technology solutions",
  "Address": "2nd floor, Trung Yen 1 building, Yen Hoa, Cau Giay, Hanoi, Vietnam",
  "View map": "View map",
};

export default locale;
