import {grayColor, mlAuto} from "assets/jss/traaax.js";

// eslint-disable-next-line max-lines-per-function
const headerLinksStyle = theme => ({
  list: {
    [theme.breakpoints.up("md")]: {
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      MsFlexDirection: "row",
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {display: "block"},
    marginTop: "0px",
    display: "flex",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
    padding: "0",
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll",
      },
      "width": "100%",
      "&:not(:last-child)": {
        "&:after": {
          width: "calc(100% - 30px)",
          content: "\"\"",
          display: "block",
          height: "1px",
          marginLeft: "15px",
          backgroundColor: grayColor[14],
        },
      },
    },
  },
  listItemText: {padding: "0 !important"},
  navLink: {
    "color": "inherit",
    "position": "relative",
    "padding": "13px 10px",
    "fontSize": "14px",
    "textTransform": "uppercase",
    "lineHeight": 1.12,
    "letterSpacing": "1.25px",
    "textDecoration": "none",
    "margin": "0px",
    "display": "inline-flex",
    "&:hover,&:focus": {color: theme.palette.primary.main},
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      "width": "calc(100% - 30px)",
      "marginLeft": "15px",
      "marginBottom": "8px",
      "marginTop": "8px",
      "textAlign": "left",
      "& > span:first-child": {justifyContent: "flex-start"},
    },
    "& svg": {
      marginRight: "3px",
      width: "24px",
      height: "24px",
    },
  },
  collapse: {
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      MsFlexPreferredSize: "auto",
      flexBasis: "auto",
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
  },
  mlAuto,
  brandImage: {
    height: 50,
    marginTop: 10,
    marginRight: 40,
    [theme.breakpoints.down("sm")]: {height: 48},
  },
});

export default headerLinksStyle;
