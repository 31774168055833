import api from "../../../../services/traaax";

export const SEARCH_RESET = "SEARCH_RESET";
export const SEARCH_ERROR = "SEARCH_ERROR";
export const SEARCH_FETCHING = "SEARCH_FETCHING";

export const SEARCH_SET_TRACK_DATA = "SEARCH_SET_TRACK_DATA";
export const SEARCH_SET_LINKS = "SEARCH_SET_LINKS";
export const SEARCH_SET_SELECTED_CHAIN = "SEARCH_SET_SELECTED_CHAIN";

export const setLinks = links => dispath => dispath(
  {
    type: SEARCH_SET_LINKS,
    payload: links,
  },
);

export const setSelectedChain = selectedChain => dispath => dispath(
  {
    type: SEARCH_SET_SELECTED_CHAIN,
    payload: selectedChain,
  },
);

export const fetchChains = ({
  community,
  scope,
  reference,
}) => async dispatch => {
  try {
    dispatch({type: SEARCH_FETCHING});
    const links = await api.fetchChains(({community, scope, reference}));
    const reversedLinks = links.reverse();

    dispatch({
      type: SEARCH_SET_LINKS,
      payload: reversedLinks,
    });

    return reversedLinks;
  } catch (error) {
    dispatch({
      type: SEARCH_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const trackReference = reference => async dispatch => {
  try {
    dispatch({type: SEARCH_FETCHING});
    const trackedData = await api.trackReference((reference));

    dispatch({
      type: SEARCH_SET_TRACK_DATA,
      payload: trackedData,
    });

    return trackedData;
  } catch (error) {
    dispatch({
      type: SEARCH_ERROR,
      payload: error,
    });

    throw error;
  }
};
