import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import localForage from "localforage";

import auth from "./auth.reducer";
import account from "./account.reducer";
import home from "app/pages/home/store/reducers";
import search from "app/pages/search/store/reducers";
import manage from "app/pages/manage/store/reducers";

const accountPersistConfig = {
  key: "accountStore",
  version: 0,
  storage: localForage,
};

const rootReducer = combineReducers({
  auth,
  home,
  search,
  manage,
  account: persistReducer(accountPersistConfig, account),
});

export default rootReducer;
