import axios from "axios";

import {domain} from "app/configs/traaax.config";
const version = "v1";
const accessTokenKey = "jwt_access_token";

const api = axios.create({
  baseURL: `${domain}/api/${version}`,
  headers: {"X-Requested-With": "XMLHttpRequest"},
  withCredentials: true,
});

const getAccessToken = () => window.localStorage.getItem(accessTokenKey);
const removeAccessToken = () => window.localStorage.removeItem(accessTokenKey);

const updateSchemaFiter = async ({id, communityId, title, idx, itemType}) => {
  const {data} = await api.put(
    `/filter/schema/${id}`,
    {communityId, title, idx, itemType},
    {headers: {Authorization: `Bearer ${getAccessToken()}`}},
  );

  return data;
};

const createSchemaFilter = async ({communityId, title, idx, itemType}) => {
  const {data} = await api.post(
    "/filter/schema",
    {communityId, title, idx, itemType},
    {headers: {Authorization: `Bearer ${getAccessToken()}`}},

  );

  return data;
};

const removeSchemaFiter = schemaFilterId => api.delete(
  `/filter/schema/${schemaFilterId}`,
  {headers: {Authorization: `Bearer ${getAccessToken()}`}},
);

const getSchemaFilters = async communityStoriesId => {
  const {data} = await api.get(
    "/filter/schema/list",
    {
      headers: {Authorization: `Bearer ${getAccessToken()}`},
      params: {communityId: communityStoriesId},
    },
  );

  return data;
};

const changeScopeState = async ({scopeId, state}) => {
  const {data} = await api.post(
    `/scope/${scopeId}/${state.toLowerCase()}`,
    null,
    {headers: {Authorization: `Bearer ${getAccessToken()}`}},
  );

  return data;
};

const changeCommunityState = async ({communityId, state}) => {
  const {data} = await api.post(
    `/community/${communityId}/${state.toLowerCase()}`,
    null,
    {headers: {Authorization: `Bearer ${getAccessToken()}`}},
  );

  return data;
};

const setSession = accessToken => {
  if (accessToken) {
    window.localStorage.setItem(accessTokenKey, accessToken);
  } else {
    window.localStorage.removeItem(accessTokenKey);
  }
};

const getCommunities = async communityName => {
  const {data} = await api.get(
    "/community/list",
    {
      headers: {Authorization: `Bearer ${getAccessToken()}`},
      params: {name: communityName},
    },
  );

  return data;
};

const getScopes = async ({scope, communityId}) => {
  const {data} = await api.get(
    "/scope/list",
    {
      headers: {Authorization: `Bearer ${getAccessToken()}`},
      params: {
        name: scope,
        communityId,
      },
    },
  );

  return data;
};

const signIn = async ({email, password, permanent = false}) => {
  const {data} = await api.post("/auth/login", {email, password, permanent});

  setSession(data.token);

  return data;
};

const trackReference = async reference => {
  const {data} = await api.get("/chain", {params: {reference}});
  return data;
};

const fetchChains = async ({community, scope, reference}) => {
  const {data} = await api.get(
    "/chain/fetch",
    {
      params: {
        community,
        scope,
        reference,
      },
    },
  );

  return data;
};

export default {
  trackReference,
  fetchChains,
  signIn,
  getCommunities,
  getScopes,
  changeCommunityState,
  changeScopeState,
  removeAccessToken,
  getSchemaFilters,
  removeSchemaFiter,
  createSchemaFilter,
  updateSchemaFiter,
};
