import _ from "lodash";

export class Utils {
  static setRoutes(config, defaultAuth) {
    let routes = [...config.routes];

    routes = routes.map(route => {
      let auth
        = config.auth || config.auth === null ? config.auth : defaultAuth || null;
      auth = route.auth || route.auth === null ? route.auth : auth;
      const settings = _.merge({}, config.settings, route.settings);

      return {
        ...route,
        settings,
        auth,
      };
    });

    return [...routes];
  }

  static generateRoutesFromConfigs(configs, defaultAuth) {
    let allRoutes = [];
    configs.forEach(config => {
      allRoutes = [...allRoutes, ...this.setRoutes(config, defaultAuth)];
    });
    return allRoutes;
  }

  static normURL(title) {
    let normUrl = title
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/gu, "")
      .replace(/đ/gu, "d");

    normUrl = normUrl.split(/[[\]()., /\\\-_'"]+/u).join("-");

    return normUrl;
  }

  static getLocation(address) {
    let district;
    let province;

    for (const addr of address.address_components) {
      if (addr.types.includes("locality") && !district) {
        district = addr.short_name;
      }

      if (addr.types.includes("administrative_area_level_2")) {
        district = addr.short_name;
      }

      if (addr.types.includes("administrative_area_level_1")) {
        province = addr.short_name;
      }
    }

    const displayName = [];
    if (district) displayName.push(district);
    if (province) displayName.push(province);

    return {
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
      district: district ? this.normURL(displayName.join(", ")) : null,
      province: province ? this.normURL(province) : null,
      displayName: displayName.join(", "),
    };
  }
}

export default Utils;
