import * as Actions from "../actions";

const initialState = () => ({
  error: null,
  fetching: false,
  data: {
    trackedData: [],
    links: [],
    selectedChain: {
      comunity: "",
      scope: "",
      reference: "",
      chain: {idx: ""},
    },
  },
});

const reducer = (state = initialState(), {type, payload} = {}) => {
  switch (type) {
  case Actions.SEARCH_SET_SELECTED_CHAIN:
    return {
      ...state,
      error: null,
      fetching: false,
      data: {
        ...state.data,
        selectedChain: payload,
      },
    };
  case Actions.SEARCH_SET_LINKS:
    return {
      ...state,
      error: null,
      fetching: false,
      data: {
        ...state.data,
        links: payload,
      },
    };
  case Actions.SEARCH_SET_TRACK_DATA:
    return {
      ...state,
      error: null,
      fetching: false,
      data: {
        ...state.data,
        trackedData: payload,
      },
    };
  case Actions.SEARCH_FETCHING:
    return {
      ...state,
      fetching: true,
    };
  case Actions.SEARCH_ERROR:
    return {
      ...state,
      fetching: false,
      error: payload,
    };
  case Actions.SEARCH_RESET:
    return initialState();
  default:
    return state;
  }
};

export default reducer;
