import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

const Loading = props => {
  const [showLoading, setShowLoading] = useState(!props.delay);
  useEffect(() => setShowLoading(true), []);
  if (!showLoading) return null;

  return (
    <div className="page-loader">
      <img
        width={216}
        className="loading-img"
        src="/assets/logo/logo_64.png"
        alt="TraaaX"
      />
    </div>
  );
};

Loading.displayName = "Loading";
Loading.propTypes = {delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])};
Loading.defaultProps = {delay: false};

export default Loading;
