import _ from "lodash";
import traaax from "../../../../services/traaax";

export const SCHEMA_FILTER_RESET = "SCHEMA_FILTER_RESET";
export const SCHEMA_FILTER_ERROR = "SCHEMA_FILTER_ERROR";
export const SCHEMA_FILTER_FETCHING = "SCHEMA_FILTER_FETCHING";

export const SCHEMA_FILTER_SET = "SCHEMA_FILTER_SET";
export const SCHEMA_FILTER_SET_SELECTED = "SCHEMA_FILTER_SET_SELECTED";

export const updateSchemaFiter = selectedFilter => async (dispatch, getState) => {
  try {
    dispatch({type: SCHEMA_FILTER_FETCHING});

    const {data} = getState().manage.schmaFilterReducer;

    const index = _.findIndex(data.schemaFilters, {id: selectedFilter.id});

    const updatedSchemaFilter = await traaax.updateSchemaFiter(selectedFilter);
    data.schemaFilters[index] = updatedSchemaFilter;

    dispatch({
      type: SCHEMA_FILTER_SET,
      payload: data.schemaFilters,
    });

    return updatedSchemaFilter;
  } catch (error) {
    dispatch({
      type: SCHEMA_FILTER_ERROR,
      payload: error,
    });

    // eslint-disable-next-line no-alert
    window.alert(error);
    throw error;
  }
};

export const createSchemaFiter = selectedFilter => async (dispatch, getState) => {
  try {
    dispatch({type: SCHEMA_FILTER_FETCHING});

    const {data} = getState().manage.schmaFilterReducer;

    const newSchemaFilter = await traaax.createSchemaFilter(selectedFilter);

    dispatch({
      type: SCHEMA_FILTER_SET,
      payload: [
        newSchemaFilter,
        ...data.schemaFilters,
      ],
    });

    return newSchemaFilter;
  } catch (error) {
    dispatch({
      type: SCHEMA_FILTER_ERROR,
      payload: error,
    });

    // eslint-disable-next-line no-alert
    window.alert(error);
    throw error;
  }
};

export const setSelectedFilter = data => dispatch => dispatch({
  type: SCHEMA_FILTER_SET_SELECTED,
  payload: data,
});

export const removeSchemaFiter = schemaFilterId => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({type: SCHEMA_FILTER_FETCHING});

    await traaax.removeSchemaFiter(schemaFilterId);

    const {data} = getState().manage.schmaFilterReducer;

    const arr = data.schemaFilters.filter(e => e.id !== schemaFilterId);

    dispatch({
      type: SCHEMA_FILTER_SET,
      payload: arr,
    });

    return data;
  } catch (error) {
    dispatch({
      type: SCHEMA_FILTER_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const fetchSchemaFilter = ({communityStoriesId}) => async dispatch => {
  try {
    dispatch({type: SCHEMA_FILTER_FETCHING});

    const data = await traaax.getSchemaFilters(communityStoriesId);

    dispatch({
      type: SCHEMA_FILTER_SET,
      payload: data,
    });

    return data;
  } catch (error) {
    dispatch({
      type: SCHEMA_FILTER_ERROR,
      payload: error,
    });

    throw error;
  }
};
