import * as Actions from "../actions";

const initialState = () => ({
  search: {
    filter: "",
    result: {},
  },
});

// eslint-disable-next-line default-param-last
const reducer = (state = initialState(), action) => {
  switch (action.type) {
  case Actions.HOME_SET_SEARCH:
    return {
      ...state,
      search: action.payload,
    };
  default:
    return state;
  }
};

export default reducer;
