import {combineReducers} from "redux";
import manageReducer from "./manage.reducer";
import schmaFilterReducer from "./manage.filter.reducer";

const reducer = combineReducers({
  manageReducer,
  schmaFilterReducer,
});

export default reducer;
