import homeConfig from "./pages/home/home.config";
import searchConfig from "./pages/search/search.config";
import manageConfig from "./pages/manage/manage.config";

const appConfigs = [
  homeConfig,
  searchConfig,
  manageConfig,
];

export default appConfigs;
