import React, {useRef} from "react";
import PropTypes from "prop-types";

import {makeStyles} from "@material-ui/core/styles";
import styles from "assets/jss/traaax/layouts/authStyle.js";

const useStyles = makeStyles(styles);

const AuthLayout = props => {
  const classes = useStyles();
  const ref = useRef();

  return (
    <>
      <div className={classes.wrapper} ref={ref}>
        <div className={classes.fullPage}>{props.children}</div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

AuthLayout.displayName = "AuthLayout";
AuthLayout.propTypes = {children: PropTypes.node.isRequired};

export default AuthLayout;
