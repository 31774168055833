import React from "react";

const searchConfig = {
  routes: [
    {
      exact: true,
      path: "/search/:reference",
      component: React.lazy(() => import("./SearchPage")),
    },
  ],
};

export default searchConfig;
