import headerLinksStyle from "assets/jss/traaax/components/headerLinksStyle.js";

const pagesStyle = theme => ({
  ...headerLinksStyle(theme),
  wrapper: {
    height: "auto",
    minHeight: "100vh",
  },
  fullPage: {
    "padding": "80px 0 40px",
    "minHeight": "100vh",
    "margin": "0",
    "border": "0",
    "backgroundSize": "cover",
    "backgroundPosition": "center center",
    "height": "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "66px 0 20px",
      minHeight: "700px!important",
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important",
    },
  },
});

export default pagesStyle;
