import traaaxService from "app/services/traaax";
import * as AccountActions from "./account.action";

export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_SUCCESSS = "AUTH_SUCCESSS";
export const AUTH_LOADING = "AUTH_LOADING";

export const login = ({
  email,
  password,
  permanent = false,
}) => async dispatch => {
  dispatch({type: AUTH_LOADING});
  try {
    const data = await traaaxService.signIn({email, password, permanent});

    dispatch(AccountActions.setAccountData({
      email,
      ...data,
    }));
    dispatch({type: AUTH_SUCCESSS});

    return data;
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error,
    });

    return error.message;
  }
};

export const logout = () => dispatch => {
  traaaxService.removeAccessToken();
  dispatch(AccountActions.removeAccountData());
};
