import React from "react";
import ReactDOM from "react-dom";
// import "@fontsource/roboto";

import "./i18n";
import App from "./app/app";

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
  document.getElementById("root"),
);
