import React, {useContext} from "react";
// import PropTypes from "prop-types";
import {renderRoutes} from "react-router-config";
import AppContext from "app/app_context";

import Suspense from "./components/suspense";
import AuthLayout from "./auth";

const Layout = () => {
  const appContext = useContext(AppContext);
  const {routes} = appContext;

  return (
    <div>
      <Suspense>
        <AuthLayout>{renderRoutes(routes)}</AuthLayout>
      </Suspense>
    </div>
  );
};

Layout.displayName = "Layout";
// Layout.defaultProps = {children: null};
// Layout.propTypes = {children: PropTypes.node};

export default React.memo(Layout);
