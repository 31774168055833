import traaax from "../../../../services/traaax";
import _ from "lodash";

export const MANAGE_RESET = "MANAGE_RESET";
export const MANAGE_ERROR = "MANAGE_ERROR";
export const MANAGE_FETCHING = "MANAGE_FETCHING";

export const MANAGE_SET_COMMUNITIES = "MANAGE_SET_COMMUNITIES";
export const MANAGE_SET_SCOPES = "MANAGE_SET_SCOPES";
export const MANAGE_SET_COMMUNITY = "MANAGE_SET_COMMUNITY";
export const MANAGE_SET_SCHEMA_FILTER = "MANAGE_SET_SCHEMA_FILTER";

export const setComunity = communityId => (dispatch, getState) => {
  const {data} = getState().manage.manageReducer;

  const {communities} = data;
  const community = communities.find(e => e.id === communityId);

  dispatch({
    type: MANAGE_SET_COMMUNITY,
    payload: community,
  });
};

export const changeScopeState = ({scopeId, state}) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({type: MANAGE_FETCHING});

    const {data} = getState().manage.manageReducer;

    const res = await traaax.changeScopeState({scopeId, state});

    const {scopes} = data.community;
    const index = _.findIndex(scopes, {id: scopeId});
    scopes[index].state = state;

    dispatch({
      type: MANAGE_SET_SCOPES,
      payload: scopes,
    });

    return res;
  } catch (error) {
    dispatch({
      type: MANAGE_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const changeCommunityState = ({communityId, state}) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({type: MANAGE_FETCHING});

    const {data} = getState().manage.manageReducer;

    const res = await traaax.changeCommunityState({communityId, state});

    const {communities} = data;
    const index = _.findIndex(communities, {id: communityId});
    communities[index].state = state;

    dispatch({
      type: MANAGE_SET_COMMUNITIES,
      payload: communities,
    });

    return res;
  } catch (error) {
    dispatch({
      type: MANAGE_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const fetchScope = ({scope, communityId}) => async dispatch => {
  try {
    dispatch({type: MANAGE_FETCHING});

    const data = await traaax.getScopes({scope, communityId});

    dispatch({
      type: MANAGE_SET_SCOPES,
      payload: data,
    });

    return data;
  } catch (error) {
    dispatch({
      type: MANAGE_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const fetchCommunity = communityName => async dispatch => {
  try {
    dispatch({type: MANAGE_FETCHING});

    const data = await traaax.getCommunities(communityName);

    dispatch({
      type: MANAGE_SET_COMMUNITIES,
      payload: data,
    });

    return data;
  } catch (error) {
    dispatch({
      type: MANAGE_ERROR,
      payload: error,
    });

    throw error;
  }
};
