import * as Actions from "../actions";

const initialState = () => ({
  error: null,
  fetching: false,
  data: {
    communities: [],
    community: {scopes: []},
    schemaFilters: [],
  },
});

// eslint-disable-next-line max-lines-per-function
const reducer = (state = initialState(), {type, payload} = null) => {
  switch (type) {
  case Actions.MANAGE_SET_SCHEMA_FILTER:
    return {
      ...state,
      error: null,
      fetching: false,
      data: {
        ...state.data,
        schemaFilters: payload,
      },
    };
  case Actions.MANAGE_SET_COMMUNITY:
    return {
      ...state,
      error: null,
      fetching: false,
      data: {
        ...state.data,
        community: payload,
      },
    };
  case Actions.MANAGE_SET_SCOPES:
    return {
      ...state,
      error: null,
      fetching: false,
      data: {
        ...state.data,
        community: {...state.data.community, scopes: payload},
      },
    };
  case Actions.MANAGE_SET_COMMUNITIES:
    return {
      ...state,
      error: null,
      fetching: false,
      data: {
        ...state.data,
        communities: payload,
      },
    };
  case Actions.MANAGE_FETCHING:
    return {
      ...state,
      fetching: true,
    };
  case Actions.MANAGE_ERROR:
    return {
      ...state,
      fetching: false,
      error: payload,
    };
  case Actions.MANAGE_RESET:
    return initialState();
  default:
    return state;
  }
};

export default reducer;
