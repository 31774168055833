// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 320;

const grayColor = [
  "#999",
  "#3C4858",
  "#eee",
  "#343434",
  "#585858",
  "#232323",
  "#ddd",
  "#6c757d",
  "#333",
  "#212121",
  "#777",
  "#D2D2D2",
  "#AAA",
  "#495057",
  "#e5e5e5",
  "#555",
  "#f9f9f9",
  "#ccc",
  "#444",
  "#f2f2f2",
  "#89229b",
  "#c0c1c2",
  "#9a9a9a",
  "#f5f5f5",
  "#505050",
  "#1f1f1f",
];
const whiteColor = "#FFF";
const blackColor = "#000";

const transition = {transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"};

const containerFluid = {
  paddingRight: "0.9375rem",
  paddingLeft: "0.9375rem",
  marginRight: "auto",
  marginLeft: "auto",
  width: "100%",
};
const container = {
  ...containerFluid,
  "@media (min-width: 80rem)": {maxWidth: "75rem"},
};

const defaultFont = {
  fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
  fontWeight: "400",
  lineHeight: "1.5em",
};

const coloredShadow = {
  // some jss/css to make the cards look a bit better on Internet Explorer
  "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {display: "none !important"},
  "transform": "scale(0.94)",
  "top": "0.75rem",
  "filter": "blur(0.75rem)",
  "position": "absolute",
  "width": "100%",
  "height": "100%",
  "backgroundSize": "cover",
  "zIndex": "-1",
  "transition": "opacity .45s",
  "opacity": "0",
};

const title = {
  color: grayColor[1],
  textDecoration: "none",
  fontWeight: "700",
  marginTop: "1.875rem",
  marginBottom: "1.5625rem",
  minHeight: "2rem",
  fontFamily: "\"Roboto\", \"Times New Roman\", serif",
};

const main = {
  position: "relative",
  zIndex: "3",
};

const mlAuto = {marginLeft: "auto"};

const buttonGroup = {
  display: "flex",
  justifyContent: "space-between",
  margin: "1.5rem 0rem",
};

const buttonGroupRight = {
  display: "flex",
  justifyContent: "flex-end",
  margin: "1.5rem 0rem",
};

export {
  // variables
  drawerWidth,
  transition,
  container,
  containerFluid,
  defaultFont,
  grayColor,
  whiteColor,
  blackColor,
  coloredShadow,
  title,
  main,
  mlAuto,
  buttonGroup,
  buttonGroupRight,
};
