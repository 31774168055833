import React from "react";

const homeConfig = {
  routes: [
    {
      path: "/",
      exact: true,
      component: React.lazy(() => import("./HomePage")),
    },
  ],
};

export default homeConfig;
